import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

export const pageQuery = graphql`
  query MDXQuery($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug }, active: { eq: true } }) {
      frontmatter {
        number
        name
        kana
        description
      }
      body
    }
  }
`;

const PokemonTemplate = ({ location, data }) => {
  const pokemon = data.mdx;

  return (
    <Layout>
      <SEO
        title={`${pokemon.frontmatter.name} in Japanese`}
        description={`We delve into the meaning behind the Japanese Pokemon name of ${pokemon.frontmatter.name}`}
      />
      <div className="border-b-2 border-black md:mt-16">
        <div className="text-right text-xl font-press-start-2p">
          <div className="mt-6 mb-4">{pokemon.frontmatter.number}</div>
        </div>
      </div>
      <div className="border-b-2 border-black">
        <div className="flex flex-col-reverse md:flex-row md:text-left md:justify-between">
          <div className="md:mt-32 mb-4">
            <h1 className="font-press-start-2p inline-block">
              {pokemon.frontmatter.name}
            </h1>
            <span className="text-base mx-2">{pokemon.frontmatter.kana}</span>
          </div>
          <div className="flex flex-row md:flex-col justify-center">
            {/* <img
              src={`/img/pokemon/${pokemon.frontmatter.number}.svg`}
              alt="Pokemon"
              width="150px"
            /> */}
            <img
              srcSet={`/img/pokemon/${pokemon.frontmatter.number}_150.png,
          /img/pokemon/${pokemon.frontmatter.number}_300.png 2x`}
              src={`/img/pokemon/${pokemon.frontmatter.number}_150.png`}
              alt="Pokemon"
              width="150px"
            />
            {/* <img
              src={`/img/pokemon/${pokemon.frontmatter.number}.png`}
              alt="Pokemon"
              width="150px"
            /> */}
          </div>
        </div>
      </div>
      {/* <aside className="text-gray-500">{pokemon.frontmatter.description}</aside> */}
      <main className="text-base md:text-xl">
        <MDXRenderer>{pokemon.body}</MDXRenderer>
      </main>
    </Layout>
  );
};

export default PokemonTemplate;
